.similarProduct::-webkit-scrollbar {
    display: none;
}

.main-content {
    display: flex;
    justify-content: space-around;
    /* align-items: center; */
    /* background-color: red; */
    width: 90%;
    flex-wrap: wrap;
}


.chip {
    display: flex;
    gap: 0.5rem
}



@media screen and (max-width: 960px) {
    .product-details {
        width: 100%;
    }
   
}

@media (min-width: 768px) and (max-width:1023px) {
    .product-details {
        width: 100%;
    }
  
}

/* // web view */
@media (min-width: 1024px)  {
    .ProductDetalt-Body{
        width: 80% !important;
        margin: auto  !important;
    }
    .product-details-sub{
        width: 80%;
        margin: auto;
        display: flex;
    }
    .detail-img-box {
        height: 300px;
        width: 250px;
    }
    
    .detail-img-box .detail-img {
        height: 100%;
        width: 100%;
        object-fit: contain;
        /* object-fit: cover; */
    }
    
    .product-details {
        /* width: 800px; */
        display: flex;
        flex-direction: column;
        gap: 1rem;
        /* background: red; */
    }
    .product-details-slide{
     width: 60%;
     margin: auto;
    }
    .product-details-title{
        width: 45%; 
        padding-top: 5%;
        padding-left: 5%;
    }
    .product-image {
        /* width: 400px; */
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .product-image h3 {
     margin-top: 10%;
     margin-left: 10%;
     text-transform: capitalize;
    }
    .ProductDetalt-dis{
        background-color: #fff;
        border-radius: 5px;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,.15);
        /* margin: 5% auto auto; */
        width: 90%;
        margin: auto;
        padding: 1%;
    }

}

/* // moblie view */
@media screen and (max-width: 1023px) {
    .ProductDetalt-Body{
        width: 100% ;
        margin: auto  
    }
    .product-details-sub{
        width: 95%;
        /* margin: auto; */
        /* display: block; */
    }
    .product-details-slide{
     /* width:95%; */
     /* margin: auto; */
     /* background: red; */
     display: block;
     /* flex-wrap: wrap; */
    }
    
    .product-image {
        /* width: 400px; */
     /* display: flex;  */
       /* justify-content: center;
        align-items: center;  */
    }
    .image-gallery {
        -webkit-user-select: none;
        -o-user-select: none;
        user-select: none;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
        width: 100%;
        position: relative;
    }
    .product-details-title{
        
    }
    .form-container{
        width: 95%;
        margin: auto;
    }
    .ProductDetalt-dis{
        background-color: #fff;
        border-radius: 5px;
        /* box-shadow: 0 2px 4px 0 rgba(0,0,0,.15); */
        /* margin: 5% auto auto; */
        width: 99%;
        margin: auto;
        margin-top: 5%;
        /* padding: 1%; */
    }
}