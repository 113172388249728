.main_card {
    width: 300px;
    margin-top:5px !important;
    margin-bottom:5px !important;
    margin-left: 5px;
    margin-right: 5px;
    /* margin-bottom: 110px; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 15px;
    height: 400px;
    /* border: 2px solid red; */
    /* border: 2px solid blue; */
}

.card_action {
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 2px solid red; */
    flex-direction: column;
}

.cart_box {
    height: 250px;
    width: 250px;
    /* padding: 5px; */
    /* border: 2px solid black; */
    margin: auto;
}

.cart_img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: center;
}

@media screen and (max-width: 960px) {
    .main_card {
        width: 280px;
        height: 350px;
        padding: 10px;
        margin-top:5px !important;
        margin-bottom:5px !important;
    }

    .cart_box {
        height: 200px;
        width: 200px;
        padding: 15px;

    }

}

/* @media (min-width: 768px) and (max-width:1023px) {} */