


/* .SlideImageBody{
    padding-top: 2%;
} */

.containerDropzone {
    display: flex;
    flex-direction: column;
    font-family: sans-serif;
  }
  
  .container > p {
    font-size: 1rem;
  }
  
  .container > em {
    font-size: .8rem;
  }


.hidden{
    height: 4rem;
    width: 100%;
    z-index: 40;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    box-shadow: 0 2px 5px -1px rgba(0, 0, 0, .08);
    position: fixed;
    top: -80px;
    transition: 0.3s linear;

}


 
 
  .HomePagw-search-bannerSub img{
    width: 100%;
    height: 50px;
  }

  .dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
  }
  
  .dropzone:focus {
    border-color: #2196f3;
  }
  
  .dropzone.disabled {
    opacity: 0.6;
    float: left;
  }


._069ac_1c5-v {
    color: #000000;
    font-size: 1rem;
    letter-spacing: .03125rem;
    margin-bottom: 0;
    padding-top: 0.6%;
    text-transform: uppercase;
    /* margin-top: %; */
    max-width: 70%;
}
.Category_mainCard__oo3Ii {
    height: 200px;
    width: 200px;
    display: flex;
    position: relative;
    cursor: pointer;
}

.home-project-all{
  float: right;
}


.circle {
  width: 25px;
  height: 25px;
  background: #9b51e0;
  border-radius: 50%;
  margin-left: 1%;
  position: relative;
  text-align: center;
  align-items: center;
  padding-top: 2.4%;
  bottom: 6px;
  color: #fff;
  margin-right: 4px;
  font-size: 12px;
  /* float: right; */
}
.Homepage-product-sub{
  display: flex;
  padding-top: 4%;
  /* width: 50%; */
}
.HomePage-Collectioncard p{
 float: right;
 /* color: red; */
 font-size: 12px;
}

@media (max-width: 768px) {
  .recharts-responsive-container {
    width: 100%;
  }
}


@media (min-width: 768px) and (max-width:1023px) {}




@media (min-width: 1024px)  {
  .HomePage-search-nav{
    width: 93%;
    margin: auto;
    /* background-color: red; */
    display: flex;
    /* padding-top: 0.2%; */
  /* width: 35%; */
    margin-left: 6%;
    z-index: 3;
    position: fixed;
    top:8.5%
  }
  .HomePagw-searchBody{
    /* height: 100px; */
    padding-top: 0.5%;
    width: 30%;
    margin-left: 0%;
    /* background-color: red; */
    /* z-index: 3; */
    /* position: fixed; */
    /* margin-bottom: 10%; */
  }
  .HomePage-search-input{
    /* background-color: red; */
    width: 90%;
}
.HomePagw-searchBody a svg{
  font-size: 20px;
  color:red;
  margin-top:2%;
  float: right;
  position: relative;
  /* bottom: 55%; */
  right:5%
}

  .HomePagw-search-banner{
    width: 69%;
    /* background-color: #000000; */
    display: flex
  }
  .HomePagw-search-bannerSub{
    width:80%;
    /* background-color: red; */
  }
  .HomePagw-search-bannerSub1{
    width: 13%;
  }
  .HomePagw-search-bannerSub1 img{
    width: 68%;
    position: relative;
    left: 17%;
    /* margin-left: 5%; */
  }
   
  .HomePagw-search-title{
    float: right;
    /* background-color: green; */
    width: 28%;
    padding-top: 10px;
  }
  .HomePagw-search-title p{
    font-size: 13px;
  }
  .homepageSlide{
    width: 85%;
    margin-left: 7%;
    display: flex;
    padding-top: 2.1%;
}
.SlideImagebody{
  width: 84%;
  margin: auto;
  /* display: flex; */
}
.SlideImage img {
  height: 90%;
  width: 100%;
}
.SlideImageLeft{
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.15);
  padding: 1%;
  /* height: 300px; */
}
.SlideImageLeft img{
  width:100% ;
  height: 365px;
}
.SlideImageRight{
  /* background-color: #000000; */
  /* display: flex; */
  flex-direction: row;
  flex-wrap: wrap;
  width: 42%;
  margin: auto;
}
.SlideImage{
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.15);
  margin: 5% auto auto;
  padding: 3%;
  width: 92%;
  /* width: 55%; */
}
.homepage-c3768_2zwQl{
  width: 90%;
height: 2.125rem;
margin: auto;
margin-top: 1.25rem;
padding: 0 0.9375rem;
background: #fff;
box-shadow: 0 2px 4px 0 rgba(0,0,0,.15);
}
.Homepage-collection-pro{
  width: 89%;
  margin: auto;
  display: flex;
  /* margin-top: 9%; */
  flex-direction: row;
  /* flex-wrap: wrap; */
  padding-left: 1%;
  height: 300px;
  overflow-x:auto;
  align-items: center;
  padding-top: 0.2%;
 /* background-color: red; */
}

.Homepage-loading{

  padding-top:7%;
  align-items: center;
  justify-content: center;
  width:25%;
  margin:auto
}
.HomePage-product-card{
  width: 16%;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.15);
  height: 263px;
  padding: 0.5%;
  /* margin-left: 1%; */
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: auto;
  /* background-color: red; */
  border-radius: 5px;
  margin-left: 5px;
  margin-top: 1%;
  /* margin-left: 1px; */
}
.Homepage-product-img{
  width: 95%;
  height: 170px;
  margin: auto;
  display: block;
  /* margin: auto; */

  /* background-color: red; */
  /* padding:2px */
}

.Homepage-product-img  img {
  width: 90%;
  height: 90%;
  margin: auto; 
}
.Homepage-product{
  width: 93%;
  margin: auto;
  display: flex;
  /* margin-top: 9%; */
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 1%;
  /* height: 500px; */
  /* overflow-y:auto; */
  align-items: center;
  padding-top: 0.2%;
 /* background-color: red; */
}
.Homepage-product-true{
  width: 93%;
  margin: auto;
  display: flex;
  /* margin-top: 9%; */
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 1%;

  /* height: 500px; */
  /* overflow-y:auto; */
  align-items: center;
  padding-top: 2.2%;
 /* background-color: red; */
}


.Homepage-ProjectBody{
  width: 90%;
  margin: auto;
  /* display: flex; */
  /* margin-top: 9%; */
  /* flex-direction: row; */
  /* margin-top: 3%; */
  overflow-x: scroll;
  white-space: nowrap;
  overflow-y: hidden;
  display: flex;
  /* padding-left: 1%; */
  /* padding-right: 1%; */
  padding-bottom: 3%;
  padding-bottom: 3%;
  height: 300px;
}

.HomePage-Collectioncard{
  width: 70%;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.15);
  height: 263px;
  /* background-color: red; */
  padding: 0.5%;
  /* margin-left: 1%; */
  align-items: center;
  justify-content: center;
  margin: auto;
  border-radius: 5px;
  margin-left: 5px;
  margin-top: 1%;
}
.home-product-img{
  width: 89%;
  margin: auto;
}
.home-product-img img{
  max-width: 100%;
  margin: auto;
  height: 190px;
  /* height: ; */
}

}

/* // moblie view */

@media screen and (max-width: 1023px) {

  .HomePage-search-nav{
    width: 100%;
    margin: auto;
    /* background-color: red; */
    display: flex;
    /* padding-top: 0.2%; */
    /* width: 35%; */
    margin-left: 0.5%;
    z-index: 3;
    position: fixed;
    /* top:8% */
  }
  .HomePagw-search-bannerSub{
    /* width:80%; */
    display: none;
    /* background-color: red; */
  }
  
  .HomePagw-search-banner{
    width: 59%;
    /* background-color: #000000; */
    display: flex
  }
  .HomePagw-searchBody{
    /* height: 100px; */
    padding-top: 0.5%;
    width: 40%;
    /* margin-left: 0%; */
    /* background-color: red; */
    /* z-index: 3; */
    /* position: fixed; */
    /* margin-bottom: 10%; */
  }
  .HomePagw-searchBody a svg{
    font-size: 25 ;
    color:red;
    margin-top:2%;
    float: right;
    position: relative;
    bottom: 55%;
    right:5%
  }

  .HomePage-search-input{
    /* background-color: red; */
    width: 100%;
}
  .HomePagw-search-bannerSub1{
    width: 23%;
    /* background-color: red; */
  }
  .HomePagw-search-bannerSub1 img{
    width: 100%;
    position: relative;
    left: 10%;
    /* margin-left: 5%; */
  }
   
  .HomePagw-search-title{
    float: right;
    /* background-color: green; */
    width: 80%;
    padding-top: 10px;
  }
  .HomePagw-search-title p{
    font-size: 13px;
  }
  .homepageSlide{
    width: 100%;
    margin: auto;
    /* display: flex; */
    padding-top: 12%;
    /* background-color: red; */
   }

  .SlideImageLeft{
    width: 100% !important;
    /* background-color: red; */
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.15);
    /* margin: 5% auto auto; */
    padding: 1%;
}
  .SlideImageLeft img{
    width:100% ;
  }

.SlideImagebody{
  width: 100%;
  /* margin: auto; */
  /* display: flex; */
}
  
.SlideImageRight{
  /* background-color: #000000; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  /* margin: auto; */
}
.SlideImage{
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.15);
  margin: 5% auto auto;
  padding: 3%;
  width: 48%;
}
.SlideImage img {

  width: 100%;
  height: 100%;
}

.homepage-c3768_2zwQl{
  width: 100%;
height: 2.125rem;
margin: auto;
margin-top: 1.25rem;
padding: 0 0.9375rem;
background: #fff;
box-shadow: 0 2px 4px 0 rgba(0,0,0,.15);
}
.homepageCategory{
  width: 95%;
  margin: auto;
  /* display: flex;
  flex-direction: row;
  flex-wrap: wrap; */
}

.Homepage-collection-pro{
  width: 100%;
  margin: auto;
  display: flex;
  /* margin-top: 9%; */
  flex-direction: row;
  /* flex-wrap: wrap; */
  /* padding-left: 1%; */
  height: 280px;
  overflow-x:auto;
  align-items: center;
  padding-top: 0.2%;
 /* background-color: red; */
}
.HomePage-product-card{
  width: 50%;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.15);
  height: 236px;
  padding: 1%;
  /* margin-left: 1%; */
  align-items: center;
  justify-content: center;
  /* margin: auto; */
  border-radius: 5px;
  /* margin-left: 5px; */
  margin-top: 1%;
  /* margin-left: 1px; */
}

.Homepage-product{
  width: 93%;
  margin: auto;
  display: flex;
  /* margin-top: 9%; */
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 1%;
  /* height: 500px; */
  /* overflow-y:auto; */
  align-items: center;
  padding-top: 0.2%;
 /* background-color: red; */
}
.Homepage-product-true{
  width: 93%;
  margin: auto;
  display: flex;
  /* margin-top: 9%; */
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 1%;

  /* height: 500px; */
  /* overflow-y:auto; */
  align-items: center;
  padding-top: 13%;
 /* background-color: red; */
}

.Homepage-loading{
  
  align-items: center;
  justify-content: center;
  width:25%;
 
  margin:auto;
  padding-top: 30%;
  /* background-color: red; */
}


.Homepage-product-img{
  width: 95%;
  height: 170px;
  margin: auto;
  /* background-color: red; */
  /* padding:2px */
}

.Homepage-product-img  img {
  width: 90%;
  height: 90%;
  margin: auto; 
}
.Homepage-product-details{
  padding:1%
}
.Homepage-product-details p{
  font-size: 12px;
}

.Homepage-ProjectBody{
  margin-top: 3%;
  overflow-x: scroll;
  white-space: nowrap;
  overflow-y: hidden;
  display: flex;
  padding-left: 1%;
  padding-right: 1%;
  padding-bottom: 3%;
  padding-bottom: 3%;
  height: 260px;
}
.home-product-img{
  width: 80%;
  margin: auto;
}
.home-product-img img{
  max-width: 95%;
  margin: auto;
  height: 180px;
  /* height: ; */
}

.HomePage-Collectioncard{
  width: 65% !important;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.15);
  height: 245px;
  /* background-color: red; */
  padding: 0.5%;
  padding-left: 1%;
  padding-right: 1%;
  /* margin-left: 1%; */
  /* align-items: center;
  justify-content: center; */
  /* margin: auto; */
  border-radius: 5px;
  margin-left: 5px;
  margin-top: 1%;
  justify-content: center;
  word-break: break-all;
  /* padding-left: 2px; */
  word-wrap: break-word;
  word-wrap:break-word;
  /* border:thin black solid; */

}
.HomePage-Collectioncard h6{
  width: 98%;
  /* background: blue; */
  font-size: 13px;
  justify-content: center;
  /* text-decoration: n; */
  word-wrap:break-word;
  /* border:thin black solid; */
  align-self: center;
  text-align: center;
  wrap: break-word; 
  overflow-wrap: break-word;
  word-wrap: break-word !important;
  text-transform: capitalize;
  word-wrap: break-word;

}
.categorylist{
  width: 85%;
  
}
}