


.collectionPeblic-show{
    width: 40%;
    padding: 1%;

}

.wmde-markdown {
display: block;
 background-color: white !important;
 color: #000 !important;
}
.publiceCollection-school{
    width: 90%;
    /* background-color: red; */
    display: flex;
    padding-left: 2%;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.15);
    margin: auto;
    padding-top: 1%;
    padding-bottom: 1%;
    border: solid blueviolet 2px;
}
.publiceCollection-school-null{
    width: 90%;
    /* background-color: red; */
    display: flex;
    padding-left: 2%;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.15);
    margin: auto;
    padding-top: 1%;
    padding-bottom: 1%;
}
.publiceCollection-school-null h3{
    margin-right: 1%;
}
.publiceCollection-school-null p{
    padding-top: 1%;
    font-size: 19px;
}
.publiceCollection-school-null h3 svg{
    position: relative;
    top:20%;
    font-size: 25px;
    }
.publiceCollection-school h3{
    margin-right: 1%;
}
.publiceCollection-school h3 svg{
position: relative;
top:20%;
font-size: 25px;
}
.publiceCollection-school p{
    padding-top: 1%;
    font-size: 19px;
}