
.collectionDetail-img{
    /* background: red; */
    width: 87%;
    height: 450px;
    margin: auto;
    padding: 1%;
    text-align: center;
}
.collectionDetail-img p {
    text-align: center;
    text-align: center;
}


.collectionDetail-img img{
  width: 95%;
  height:95%;
  text-align: center;
  margin: auto;
  text-align: center;
}



.collectionDetail-Buy{
    /* background-color: red; */
    /* text-align: right; */
    /* margin-bottom: 10%; */
    
}

.new-article-body{
    
}

.collectionDetail-quantity{
float: right;}


.newarticle-later{
    width:100%;
    box-shadow: 0 0 40px 0px rgba(0, 0, 0, 0.17);
    background:#fff;
    margin-top:1% ;
    margin-bottom: 3%;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 10%;
    padding-top: 10%;
}
.react-markdown--toolbar .btn {
    margin-bottom: 10px;
    border: 0;
    box-shadow: none;
    margin-left: 20px;
    cursor: pointer;
    margin-top: 10px;
}
.react-markdown--plain-markdown-input__left-border{
    position: relative;
    right: 3%;
}





.collectDetail-share ul li{
    display:inline;
    margin-left: 2%;
}

.collection-detailPage-2{
    width: 60%;
    display: flex;
    padding-top: 3%;
    /* background: red; */
    flex-direction: row;
flex-wrap: wrap;
}
.image-and-select{
    width: 65%;
    display: flex;
    float: right;
    
    /* height: 2px; */
}

select {
    -webkit-appearance:none;
    -moz-appearance:none;
    -ms-appearance:none;
    appearance:none;
    outline:0;
    margin-right: 5%;
    box-shadow:none;
    border:0!important;
    background: #5c6664;
    background-image: none;
    flex: 1;
    padding: 0 .5em;
    color:#fff;
    cursor:pointer;
    font-size: 1em;
    font-family: 'Open Sans', sans-serif;
 }
 select::-ms-expand {
    display: none;
 }
 .select {
    position: relative;
    display: flex;
    width: 17em;
    height: 3em;
    line-height: 3;
    background: #5c6664;
    overflow: hidden;
    border-radius: .25em;
 }
 .select::after {
    content: '\25BC';
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 1em;
    background: #2b2e2e;
    cursor:pointer;
    pointer-events:none;
    transition:.25s all ease;
 }
 .select:hover::after {
    color: #23b499;
 }

@media (min-width: 1024px)  {

    .collection-detail{
        width:95%;
        margin: auto;
        display: flex;
        margin-bottom: 5%;
    }


    .collection-detail-show{
        width: 40%;
    }

    .productDetail-title{
        margin-left: 5%;
        text-transform: capitalize;
        position: relative;
        bottom: 35px;
    }

    .collectionDetail-list{
        width: 65%;
        display: block;
        padding-top: 3%;
        /* background-color: red; */
    }
    .collectionDetail-price{
        /* float: right; */
        width: 100%;
        height: 25px;
        /* background-color: red; */
        text-align: right;
    }
    .collectionDetail-moblie{
        display: none;
    }
    .collectionDetail-listName{
        width:68%;
        /* text-/'transform:capitalize */
        /* background-color: red; */
        padding-left: 5%;
    }
    
    .collectionDetail-web{
        display: block;
    }
    .collectionDetail-list-sub{
        width: 95%;
        display: flex;
        margin-top: 4%;
        flex-direction: row;
        /* overflow-x: scroll; */
        /* white-space: nowrap; */
        flex-wrap: wrap;
        padding-left: 3%;
        height: 500px;
        overflow-y:auto;
        align-items: center;
        padding-top: 1%;
        /* margin: auto; */
        }
        
        .collectionDetail-product-img{
            width: 95%;
            height: 170px;
            margin: auto;
            /* background-color: red; */
            /* padding:2px */
        }
        
        .collectionDetail-product-img  img {
            width: 90%;
            height: 90%;
            margin: auto; 
        }
        .collectionDetail-product{
            width: 24.5%;
            box-shadow: 0 2px 4px 0 rgba(0,0,0,.15);
            height: 240px;
            padding: 0.5%;
            /* margin-left: 1%; */
            align-items: center;
            justify-content: center;
            margin: auto;
            margin-left: 1px;
            /* margin-top: 0.3%; */
        }
        .collectionDetail-price h4{
            text-align: right;
         }
         .collectDetail-share{
            width: 90%;
            margin: auto;
            margin-top: 1%;
          }
          .collectionReview-no-com{
            /* text-align: left; */
            width: 90%;
            margin: auto;
            padding-top: 1%;
            text-align: center;
            /* background-color: red; */
        }
        .collectionReview-no-com p{
            text-align: center !important;
        }
        .collectionDetail-but-image{
            width: 11%;
            min-width: 10%;
            min-height: 35px;
            float: right;
            position: relative;
            bottom: 20px;
            right: 8%;
            background: rgb(255, 94, 0);
        }
        .collectionDetail-image-url{
            width:100%;
            /* background-color: red; */
            margin-left: 5%;
            padding-top: 15%;
        }
        .collectionDetail-image-url img{
          max-width:95%;
        }
}



/* // moblie view */

@media screen and (max-width: 1023px) {
    .collection-detail{
        width:100%;
        margin: auto;
        /* display: flex; */
        margin-bottom: 5%;
    }
    .collectionDetail-image-url{
        width: 100%;
        text-align: center;
        /* background-color: red; */
        /* margin-left: 5%; */
        /* padding-top: 15%; */
    }
    .collectionDetail-image-url img{
       max-width: 95%;
    }
    .collectionDetail-price h4{
        /* text-align: right; */
        float: right;
        text-align: right;
     }
     .collectDetail-share{
        width: 100%;
        margin: auto;
        margin-top: 1%;
      }
     .collectionDetail-share{
         width: 100%;
         /* text-align: left; */
         height: 30px;
         margin: auto;
         padding-top: 1%;
         /* background-color: red; */
         text-align: right;
         /* text-align: right; */
         /* justify-content: right; */
         /* align-items: left; */
         margin-top: 10%;
         margin-bottom: 20%;
         /* padding-left: 100px; */
         /* padding-left: 10%; */
         /* float: right; */
     }
     .collectionDetail-listName{
         /* background-color: red; */
        width:100%;
        padding-bottom: 7%;
        /* text-/'transform:capitalize */
        /* background-color: red; */
    }
    .collectionDetail-listName h3{
        float: left;
        text-align: left;
        width: 78%;
        /* background-color: goldenrod; */
    }
 

.collection-detail-show{
    width: 100%;
    margin-top: 18%;
    /* background-color: red; */
}
.collectionDetail-list{
    width: 100%;
    display: block;
    padding-top: 3%;
    /* background-color: red; */
}
.collectionDetail-web{
    display: none;
}
.collectionDetail-moblie{
    margin-top: 10%;
    margin-bottom: 23%;
    /* text-align: right; */
}
.collectionDetail-price{
    /* float: right; */
    width: 98%;
    margin: auto;
    height: 25px;
    /* background-color: red; */
    text-align: left
}
.collectionDetail-list-sub{
    margin-top: 3%;
    overflow-x: scroll;
    white-space: nowrap;
    overflow-y: hidden;

    padding-left: 1%;
    padding-right: 1%;
    padding-bottom: 3%;
    padding-bottom: 3%;
    /* margin: auto; */
    }
    .collectionDetail-product{
        width:60%;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,.15);
        height: 230px;
        padding: 0.5%;
        display: inline-block;
        /* margin-left: 1%; */
        align-items: center;
        justify-content: center;
        margin: auto;
        margin-left: 1px;
        margin-top: 1%;
        display: inline-block;
    }
.collectionDetail-product-img{
    width: 82%;
    height: 170px;
    margin: auto;
    align-items: center;
  justify-content: center;
    /* background-color: red; */
    /* padding:2px */
}

.collectionDetail-product-img  img {
    width: 100%;
    height: 100%;
    margin: auto; 
    align-items: center;
  justify-content: center;
}
.collectionReview-no-com{
    text-align: left;
    width: 90%;
    margin: auto;
    padding-top: 1%;
    /* background-color: red; */
}

}