.nav {
    display: flex;
    justify-content: space-around;
    gap: 220px;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 7px;
    height: 60px;
    /* padding: 10px; */
    /* margin: 5px; */
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 0;
    width: 100%;
    
    z-index: 1000;
    background: white;
    
    /* background-color: red; */
    /* border-radius: 10px; */
}

nav .logo {
    /* background-color: green; */
    /* width: 100px;
    font-weight: 800;
    font-size: 25px; */

}


nav ul {
    display: flex;
    line-height: 40px;
    /* margin-right: 1rem; */
}

nav ul li {
    list-style-type: none;
}

nav ul li a {
    display: block;
    margin: 0 2px;
    padding: 8px 18px;
    border-radius: 10px;
    font-weight: 600;

}

.nav-icon-span {
    font-size: 18px;
    
}

.nav-items {
    line-height: 40px;
    /* margin-right: 1rem; */
}

nav ul .nav-links:after {
    content: "";
    display: block;
    height: 3px;
    width: 0;
    background: transparent;
    transition: width 0.7s ease, background-color 0.5s ease;
}

nav ul .nav-links:hover:after {
    width: 100%;
    background: #1976d2;
}

.nav-icon:not(:last-child) {
    font-size: 29px;
}

@media screen and (max-width: 900px) {


    .nav ul {
        display: none;
    }

    .nav {
        display: block;
    }
}


@media (min-width: 900px) and (max-width:1096px) {
    .nav {
        gap: 10px;
        justify-content: space-around;
    }
}
.nav-drop-body{
    width: 15%;
   height: 300px;
   /* background-color:red; */
   /* padding-top: 0.1%; */
   position: absolute;
   right: 7%;
   top: 40px;
}
.triangle_up1 {
    position: relative;
    /* top: -5px; */
    left: 80%;
    content: "";
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    display: inline-block;
    width: 15px;
    height: 15px;
    border-right: 0.2em solid #121212;
    border-top: 0.2em solid #121212;
    transform: rotate(-45deg);
    margin-right: 0.5em;
    margin-left: 1.5em;
    
    /* float: right; */

  }


.nav-drop{
   width: 102%;
   height: 300px;
   background-color:#fff;
   box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
   border-radius: 5px;
   position: relative;
   bottom:17px;
}


@media (min-width: 1024px)  {
    .logo a img{
        width: 7% !important;
      }
      .nav-Moblie {
        line-height: 40px;
        /* margin-right: 1rem; */
    }
    .nav-Moblie{
        display: none;
    }
    .menu{
        display: none;
        
    }
}


/* // moblie view */

@media screen and (max-width: 1023px) {
    .logo a img{
        width: 11% !important;
      }

      .nav-Moblie {
          width: 15%;
          float: right;
        /* line-height: 40px; */
          height: 40px;
          padding-top: 1%;

        /* display: flex; */
        /* background-color: red; */
        /* margin-right: 1rem; */
    }
    .nav-setup{
        /* float: right; */
        align-items: left;
        text-align: left;
        display: block !important;
        display: inline;
        /* background: green; */
    }
    .nav-setup li{
        display: inline !important;
        width: 10% !important;
        /* background: green; */
        padding: 0px !important;
        /* margin-left: 1%; */
    }
    .nav-setup li a{
        display: inline;
        font-size: 13px;
    }
    .menu-sideNav{
        width: 100%;
        padding-left: 3%;
        margin-top: 10%;
        margin-bottom: 10%;
        padding-top: 5%;
        padding-bottom: 5%;
        font-size: 18px;
        border-right: solid 1px #3C3735;
        /* border-bottom: solid 1px #3C3735; */
    }

}