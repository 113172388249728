


@media (min-width: 1024px)  {

    .orderBody-sub{
        width: 80%;
        margin: auto;
    }
    

}



/* // moblie view */

@media screen and (max-width: 1023px) {

    .orderBody-sub{
        width: 100%;
        margin: auto;
    }
    

}