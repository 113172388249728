.total-card {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 90px;
    background-color: green;
}

.total-card {
    width: 30%;
    background-color: white;
    border: 2px solid black;
}

.card-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.main-card {
    width: 500px;
    margin: 30px 10px 40px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* flex-direction: column; */
    flex-wrap: wrap;
    padding: 10px;
    height: 540px;
}

.main-cart-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.cart-img-box {
    height: 100px;
    width: 100px;
    border: 2px solid black;
}

.cart-img-box .cart-img {
    height: 100%;
    width: 100%;
    object-fit: fill;
}

.cart-img {
    width: 200px;
    height: 150px;
}

.total-card {
    background-color: red;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.empty-cart-img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}
.orderSummary{
    width: 100%;
     display: flex;
     justify-content: space-between;
     margin-top: 2 ;
    background: #fff !important;
    border-radius: 1px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.15);
    margin-top: 5px;
    margin-top: 5px;
    padding-left: 1%;
    padding-right: 1%;
}
.orderSummary-select{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 2 ;
   background: #fff !important;
   border-radius: 1px;
   box-shadow: 0 2px 4px 0 rgba(0,0,0,.15);
   margin-top: 5px;
   margin-top: 5px;
   padding-left: 1%;
   padding-right: 1%;
   border:solid 2px #1976d2
}

.cart-title{
justify-content: space-between;
     margin-top: 2 ;
    background: #fff !important;
    border-radius: 1px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.15);
    margin-top: 5px;
    margin-top: 5px;
    padding-left: 1%;
    padding-right: 1%;
}
@media screen and (max-width: 960px) {
    .main-card {
        width: 300px;
        height: 350px;
        
    }

    .total-card {
        background-color: green;
    }

    .cart-img {
        width: 150px;
        height: 150px;
        object-fit: contain;
    }
    
}


@media (min-width: 768px) and (max-width: 1023px) {
    .total-card {
        background-color: blue;
    }

    .cart-img {
        width: 150px;
        height: 150px;
        object-fit: contain;
    }

}


@media (min-width: 1024px)  {

    .cart-body{
        width: 95%;
        display: flex;
        margin: auto;
    }

    .cart-product{
        width: 65%;

    }
    .cart-process{
        width: 35%;
        padding-left: 1%;
    }
    .Cart-product-card{
        width: 25%;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,.15);
        height: 290px;
        padding: 0.5%;
        /* margin-left: 1%; */
        align-items: center;
        justify-content: center;
        text-align: center;
        margin: auto;
        /* background-color: red; */
        border-radius: 5px;
        margin-left: 5px;
        margin-top: 1%;
        /* margin-left: 1px; */
      }

}


/* // moblie view */

@media screen and (max-width: 1023px) {
    .cart-body{
        width: 100%;
        /* display: block; */
        margin: auto;
    }

    .cart-product{
        width: 100%;
    
    }
    .cart-process{
        width: 100%;
        padding-left: 1%;
        position: relative;
        bottom: 29px;
    }
    .Cart-product-card{
        width: 49%;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,.15);
        height: 290px;
        padding: 0.5%;
        /* margin-left: 1%; */
        align-items: center;
        justify-content: center;
        text-align: center;
        margin: auto;
        /* background-color: red; */
        border-radius: 5px;
        margin-left: 1px;
        margin-top: 1%;
        /* margin-left: 1px; */
      }
}