.CollectionReview-form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 4%;
    /* gap: 220px; */
    /* flex-wrap: wrap; */
    /* padding: 50px; */
    /* background-color: red; */
}

.CollectionReview-box {
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: center; */
    width: 90%;
    margin: auto;
    /* margin: 50px auto; */
    /* border: 2px solid black;/ */
    font-size: 1rem;
}
.collectionReview-Form{
    margin-top: 4%;
    margin-left: 4%;
}

