


.collectionList-search{
    /* background-color: red; */
    width: 90%;
}

.collectionList-searchBar{
    width: 100% !important;
    max-width: 100% !important;
    border-radius: 0;
    display: flex;
     justify-content: space-between;
      padding: 2px 15px;
      align-items: center;
      background-color: #fff;
      margin-top: 1%;
      margin-bottom: 1%;
}





.collectionslist-detail-body{
 width: 80%;
 /* display: f    */
}
.collectionslist-card{
    display: flex;
}



.collectionslist-modal{
    width: 100%;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.15);
    display: flex;
    padding-top: 1%;
    padding-bottom: 1%;
}

.collectionslist-product-modal{
    width:60%;
    padding-top: 10px;
    padding-left: 4px;
    display: flex;
    overflow-x:auto;
}
.collectionslist-sub-modal{
    width: 13%;
    height: 55px;
    margin-left: 0.5%;
    padding-top: 3px;
    padding-bottom: 3px;
}
.collectionslist-sub-modal img{
    width: 80%;
    height: 89%;
}
@media (min-width: 1024px)  {
    .collectionslist-full{
        position:fixed;
        width: 100%;
        z-index: 3;
        top: 8%;
    }
    .collectionslist-detail{
        width: 20%;
        padding-top: 0.5%;
        text-align: right;
        padding-right: 2%;
    }
    .collectionslist-product{
        width: 55% ;
        display: flex;
        height: 50px;
       
      }
      .collectionslist-sub{
        width: 8%;
        height: 50px;
        margin-left: 0.5%;
        padding-top: 3px;
        padding-bottom: 3px;
    }
    .collectionslist-sub img{
        width: 80%;
        height: 89%;
    }
    .collectionslist-price{
        width: 20%;
        padding-top: 0.5%;
        text-align: right;
        padding-right: 2%;
    }
    .collectionslist-price svg{
        font-size: 27px;
        /* background-color: red; */
    }
    .collectionslist-price p{
        float: right;
    }
    .collectionslist-price h4{
        margin-top: 2%;
    }
    
    
    .collectionList-product-card{
        width: 16.5%;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,.15);
        height: 280px;
        padding: 0.5%;
        /* margin-left: 1%; */
        align-items: center;
        justify-content: center;
        margin: auto;
        margin-left: 1px;
        margin-top: 1%;
        /* margin-left: 1px; */
    }
    .collectionList-searchBody{
        /* height: 100px; */
        padding-top: 2.5%;
        width: 35%;
        margin-left: 1%;
        z-index: 3;
        position: absolute;
        margin-bottom: 30%;
    }
    .collection-data {
        width:90%;
        min-width:90%;
        min-height: 35px;
        padding-left: 5px;
        padding-right: 5px;
        position: relative;
        left: 15%;
        /* float: right; */
        background: rgb(255, 94, 0);
    }
    .collectionList-product{
        padding-top: 4%;
        
    }
    
    .collection-data-moblie{
        display: none
    }
    .collectionslist-body{
        width: 100%;
        background-color:#49A5C61A;
        height:auto;
        padding-top: 0.6%;
        /* margin-top: 20px; */
        /* margin-bottom: 10%; */
        padding-left: 7%;
        padding-right: 7%;
       
        display: flex;
       
    }
    .collectionslist-modalBody{
    
    }
    .collectionslist-detail-modal{
        width:20%;
        padding-top: 3%;
        padding-left: 2%;
    }
    .collectionslist-detail-modal h5{
        font-size: 10px;
    }
    .collectionslist-detail-modal h6{
        text-align: right;
        position: relative;
        right:20%
    }
    .collectionslist-delete-btn{
        align-items: center;
        justify-content: center;
        text-align: center;
        padding-top: 2%;
    }
    .collectionslist-delete-btn h4{
        margin-bottom: 2%;
    }
}
/* // moblie view */

@media screen and (max-width: 1023px) {
    .collectionslist-full{
        position:fixed;
        width: 100%;
        z-index: 3;
        top: 8%;
    }
    .collectionslist-body{
        width: 100%;
        background-color:#49A5C61A;
        height:45px;
        padding-top: 0.6%;
        /* margin-top: 20px; */
        /* margin-bottom: 10%; */
        padding-left: 2%;
        padding-right: 2%;
        display: flex;
       
    }
    .collectionslist-detail{
        width: 30%;
        padding-top: 0.5%;
        text-align: left;
        /* padding-right: 2%; */
    }
    .collectionslist-detail  h5{
        font-size: 12px !important;
        /* background-color: red  */
    }
    .collectionList-product-card{
        width: 49%;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,.15);
        height: 255px;
        padding: 0.5%;
        /* margin-left: 1%; */
        align-items: center;
        justify-content: center;
        margin: auto;
        margin-left: 1px;
        margin-top: 1%;
        /* margin-left: 1px; */
    }
    .collectionslist-sub{
        width: 15%;
        height: 35px;
        margin-left: 0.5%;
        padding-top: 3px;
        padding-bottom: 3px;
    }
    .collectionslist-sub img{
        width: 80%;
        height: 89%;
    }
    .collectionslist-price{
        width: 20%;
        padding-top: 0.5%;
        text-align: right;
        padding-right: 2%;
    }
    .collectionslist-price p{
        float: right;
    }
    .collectionslist-price h4{
        /* margin-top: 0.3%; */
        font-size: 10px;
        position: relative;
        bottom: 5px;
    }
    .collectionslist-price svg{
        font-size: 20px;
        /* background-color: red; */
    }
    .collectionList-searchBody{
        /* height: 100px; */
        padding-top: 7.5%;
        width: 99%;
        margin: auto;
        margin-left: 1%;
        z-index: 3;
        /* position: absolute; */
        /* margin-bottom: 1%; */
    }
    .collectionList-product{
        /* padding-top: 1% */
    }
    
    .collectionslist-product{
        width: 60% ;
        display: flex;
        height: 50px;
       
      }
    .collection-data {
       display: none;
    }
    
    .collection-data-moblie{
        width:50%;
        min-width:50%;
        height: 30px;
        /* min-height: 10px; */
        /* padding-top: 1%; */
        padding-left: 5px;
        padding-right: 5px;
        position: relative;
        left: 50%;

        /* float: right; */
        background: rgb(255, 94, 0);
    }
    .collection-data-moblie svg{
        font-size: 40px;
        color: #fff;
    }
    .collectionslist-modalBody{
        width: 100%;
        padding: 3% !important;
        /* background-color: red; */
    }
    .collectionslist-detail-modal{
        width:30%;
        padding-top: 3%;
        padding-left: 2%;
    }
    .collectionslist-detail-modal h5{
        font-size: 10px;
    }
    .collectionslist-detail-modal h6{
        text-align: right;
        position: relative;
        right:20%
    }
    .collectionslist-delete-btn{
        /* background-color: ; */
        align-items: center;
        justify-content: center;
        text-align: center;
        padding-top: 2%;
        width: 100%;
    }
    .collectionslist-delete-btn h4{
        margin-bottom: 2%;
    }
   
}
