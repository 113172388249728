
.Collections-body{
  width:90%;
  margin: auto;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.15);
}



.collectionRight{
    width: 79%;
    /* background-color: red; */
    padding-left: 0.5%;
    padding-top: 1%;
    padding-right: 1%;
    display: block
    /* margin: auto; */
}
.collectionprice-body{
    width: 100%;
    height: 30px;
}

.collection-detail h5{
    float: right;
}
.collection-detail h4{
   margin-top: 0.3%;
}
.collection-detail h2{
    margin-bottom: 1%;
}

.collectionDetail-delete{
    float: right;
}

.collection-price p{
    text-align: right;
}
.collection-price h5{
    /* width: 20%; */
    float: right;
    color: #007aff;
}
.collections-title{
    width: 80%;
    margin: auto;
    height: 100px;
    margin-top: 8%;
    margin-bottom: 1%;
}
.collections-null{
    margin-bottom: 1%;
}
.collections-title h3{
    font-size: 1.4rem;
}
.contained-delete-btn{
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-top: 2%;
}
.contained-delete-btn h4{
    margin-bottom: 2%;
}

.buttom-make{
    font-size: 3rem;
    /* font-weight: bold; */
    /* font-weight: 370; */
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    align-self: start;
    color: #fff;
    margin: auto;
    border-radius: 8px;
    /* box-shadow: 0 3px 5px rgba(0, 0, 0, 0.18); */
    padding-top: 1px;
    text-align: center;
    transition: 220ms all ease-in-out;
    position: relative;
    /* box-shadow: 0 2px 5px 0 rgba(235, 229, 229, 0.16), 0 2px 10px 0 rgba(245, 241, 241, 0.87); */
    box-shadow: 0 2px 5px 0 rgb(240, 233, 233), 0 2px 10px 0 rgb(245, 241, 241);
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
}


.collection-add-pro{
    width: 5%;
    min-width: 5%;
    min-height: 35px;
    /* float: right; */
    background: rgb(255, 94, 0);
}


@media (min-width: 1024px)  {
    .collections-title{
        width: 80%;
        margin: auto;
        height: 100px;
        margin-top: 8%;
        margin-bottom: 1%;
    }
    .collection-add{
        width: 12%;
        min-width: 12%;
        min-height: 45px;
        float: right;
        background: rgb(255, 94, 0);
    }
    .collections-null{
        width:65%;
      margin: auto;
      height: auto;
      box-shadow: 0 2px 4px 0 rgba(0,0,0,.15);
      padding-bottom: 0.5%;
      padding-left: 0.5%;
      border-radius: 5px;
      margin-top: 1%;
       margin-bottom: 1%;
      /* display: flex; */
    }
    .collections{
        width:65%;
      margin: auto;
      height: 170px;
      box-shadow: 0 2px 4px 0 rgba(0,0,0,.15);
      display: flex;
      border-radius: 10px;
       margin-top: 1%;
       margin-bottom: 1%;
    }
    .collection-show{
        width: 22%;
        padding-left: 1%;
  
      }
      .collection-show img{
          width: 95%;
          height: 95%;
       
      }
      .collectionRight h3{
        margin-top: 2%;
      }
      .collection-detail-null{
        width: 100%;
        /* background-color: red; */
        padding-left: 0.5%;
        padding-top: 1%;
        padding-right: 1%;
    }
    .collection-detail-null h3{
        position: relative;
        bottom: 5px;
    }

    .collection-list{
        display: flex;
        margin-top: 2%;
        /* background-color: red; */
        width: 100%;
        position: relative;
        top:15%
    }
    .collectionprice {
        width: 12%;
        float: right; 
        /* background-color: red; */
    }
    .collectionprice p{
        text-align: right;
        position: relative;
        left: 3%;
    }
    .collectionprice p a svg{
        font-size: 25px;
         margin-top: 7;
         /* background-color: red; */
    }
    .collection-list-sub{
        width: 10%;
        height: 64px;
        margin-left: 0.5%;
    }
    .collection-list-sub img{
        width: 95%;
        height: 95%
    }
}





/* // moblie view */

@media screen and (max-width: 1023px) {
    .collections-title{
        width: 99%;
        margin: auto;
        height: 100px;
        margin-top: 8%;
        margin-bottom: 1%;
    }
    .collection-add{
        width: 32%;
        min-width: 12%;
        min-height: 45px;
        float: right;
        background: rgb(255, 94, 0);
    }
    .collections-null{
        width:98%;
      margin: auto;
      height: auto;
      box-shadow: 0 2px 4px 0 rgba(0,0,0,.15);
      padding-bottom: 0.5%;
      padding-left: 0.5%;
      border-radius: 5px;
      margin-top: 1%;
       margin-bottom: 1%;
      /* display: flex; */
    }
    .collections{
        width:98%;
      margin: auto;
      height: 110px;
      box-shadow: 0 2px 4px 0 rgba(0,0,0,.15);
      display: flex;
      border-radius: 10px;
      margin-top: 1%;
       margin-bottom: 1%;
      
    }
    .collection-show{
        width: 20%;
        padding-left: 1%;
        padding-top: 5%;
      }
      .collection-show img{
          width: 90%;
          height: 85%
      }
      .collectionRight h3{
        margin-top: 2%;
      }
      .collection-detail-null{
        width: 100%;
        /* background-color: red; */
        padding-left: 0.5%;
        padding-top: 1%;
        padding-right: 1%;
    }
    .collection-detail-null h3{
        position: relative;
        bottom: 5px;
    }
    .collection-list{
        display: flex;
        /* margin-top: 2%; */
        /* background-color: red; */
        width: 100%;
        /* background-color: red; */
        position: relative;
        top:15%
    }
    .collectionprice {
        width: 22%;
        float: right; 
        /* background-color: red; */
    }
    .collectionprice p{
        text-align: right;
        position: relative;
        left: 3%;
    }
    .collectionprice p a svg{
        font-size: 20px;
         margin-top: 7;
         /* background-color: red; */
    }
    .collection-list-sub{
        width: 10%;
        height: 34px;
        margin-left: 0.5%;
     
    }
    .collection-list-sub img{
        width: 95%;
        height: 95%
    }
}
