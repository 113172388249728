.adminSection-button{
 margin-top: 2%;
}
.adminSection{
    /* width: 15%; */
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
    background-color: chocolate;
}
.adminSection-searchBody{
    /* height: 100px; */
    padding-top: 2%;
    width: 35%;
    margin-left: 1%;
    z-index: 3;
    /* position: absolute; */
    margin-bottom: 10%;
}
.adminSection-searchCollection{
    display: flex;
    width: 85%;
    height: 40px;
    /* background-color: blue; */
}
.adminSection-collection{
    width: 35%;
    background-color: red;
    position: relative;
    top:50%;
    display: flex;
}
.adminSection-product{
    padding-top: 5.5%;
}
.adminSectionprice {
    width: 17%;
    float: right; 
    /* background-color: red; */
}
.adminSection-close{
    width: 10%;
    float: right;
    height: 15px;
    /* background-color: red; */
    position: relative;
    top:20px
}
.adminSection{
    margin-left: 1%;
}