@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600;700&display=swap");
:root {
  --color-white-100: hsl(206, 5%, 100%);
  --color-white-200: hsl(206, 5%, 90%);
  --color-white-300: hsl(206, 5%, 80%);
  --color-white-400: hsl(206, 5%, 65%);
  --color-white-500: hsl(206, 5%, 50%);
  --color-black-100: hsl(213, 20%, 10%);
  --color-black-200: hsl(213, 23%, 8%);
  --color-black-300: hsl(214, 21%, 6%);
  --color-black-400: hsl(210, 21%, 6%);
  --color-black-500: hsl(216, 22%, 4%);
  --color-black-600: hsl(220, 18%, 3%);
  --color-black-700: hsl(220, 27%, 2%);
  --color-black-800: hsl(180, 20%, 1%);
  --color-blue-100: hsl(214, 95%, 93%);
  --color-blue-200: hsl(213, 97%, 87%);
  --color-blue-300: hsl(212, 96%, 78%);
  --color-blue-400: hsl(213, 94%, 68%);
  --color-blue-500: hsl(217, 91%, 60%);
  --color-blue-600: hsl(221, 83%, 53%);
  --color-blue-700: hsl(224, 76%, 48%);
  --color-blue-800: hsl(226, 71%, 40%);
  --color-blue-900: hsl(224, 64%, 33%);
  --shadow-small: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
     0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --shadow-medium: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
     0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-large: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
     0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.container {
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 0 1.25rem;
  height: -webkit-fill-available;
  color: var(--color-white-100);
  background-color: var(--color-black-500);
}


.heading-xl {
  font-family: inherit;
  font-size: clamp(2.648rem, 6vw, 4.241rem);
  font-weight: 700;
  line-height: 1.15;
  letter-spacing: -1px;
}

.paragraph {
  font-family: inherit;
  font-size: clamp(1rem, 2vw, 1.125rem);
  text-wrap: balance;
  color: var(--color-white-200);
}

.btn {
  display: inline-block;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  user-select: none;
  outline: none;
  border: none;
  border-radius: 0.25rem;
  text-transform: unset;
  transition: all 0.3s ease-in-out;
}
.btn-inline {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  column-gap: 0.5rem;
}
.btn-darken {
  padding: 0.75rem 2rem;
  color: var(--color-white-100);
  background-color: var(--color-black-200);
  box-shadow: var(--shadow-medium);
}
.btn-neutral {
  padding: 0.75rem 2rem;
  color: var(--color-black-500);
  background-color: var(--color-white-100);
  box-shadow: var(--shadow-medium);
}

.brand {
  font-family: inherit;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: -1px;
  color: var(--color-white-100);
  text-transform: uppercase;
 
}

@media only screen and (min-width: 48rem) {
  
  .menu-inner {
    display: flex;
    flex-direction: row;
    column-gap: 2rem;
    margin: 0 auto;
  }
  .menu-link {
    text-transform: capitalize;
  }
  .menu-block {
    margin-left: 2rem;
  }
}


@media only screen and (min-width: 48rem) {
  .burger {
    display: none;
    visibility: hidden;
  }
}
.burger-line {
  position: absolute;
  display: block;
  left: 0;
  width: 100%;
  height: 2px;
  border: none;
  outline: none;
  opacity: 1;
  border-radius: 1rem;
  transform: rotate(0deg);
  background-color: var(--color-white-100);
  transition: 0.25s ease-in-out;
}
.burger-line:nth-child(1) {
  top: 0px;
}
.burger-line:nth-child(2) {
  top: 0.5rem;
  width: 70%;
}
.burger-line:nth-child(3) {
  top: 1rem;
}
.burger.is-active .burger-line:nth-child(1) {
  top: 0.5rem;
  transform: rotate(135deg);
}
.burger.is-active .burger-line:nth-child(2) {
  opacity: 0;
  visibility: hidden;
}
.burger.is-active .burger-line:nth-child(3) {
  top: 0.5rem;
  transform: rotate(-135deg);
}

.banner-column {
  position: relative;
  display: grid;
  align-items: center;
  row-gap: 3rem;
}
@media only screen and (min-width: 48rem) {
  .banner-column {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    justify-content: center;
  }
}
@media only screen and (min-width: 64rem) {
  .banner-column {
    grid-template-columns: 1fr max-content;
    column-gap: 4rem;
    margin-top: 0.1rem;
  }
}
.banner-image {
  display: block;
  max-width: 12rem;
  height: auto;
  margin-top: 0.5rem;
  object-fit: cover;
  justify-self: center;
}
@media only screen and (min-width: 48rem) {
  .banner-image {
    order: 1;
    max-width: 20rem;
    height: auto;
  }
}
@media only screen and (min-width: 64rem) {
  .banner-image {
    max-width: 25rem;
    height: auto;
    margin-right: 5rem;
  }
}
.banner-inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 1.75rem;
}
.banner-links {
  position: absolute;
  top: 50%;
  right: 1.5rem;
  display: grid;
  justify-items: center;
  row-gap: 0.5rem;
  opacity: 0;
  visibility: hidden;
}
@media only screen and (min-width: 64rem) {
  .banner-links {
    opacity: 1;
    visibility: visible;
  }
}
.banner-links > * {
  font-size: 1.25rem;
  line-height: 1.25;
  color: var(--color-white-100);
}
.banner-links::before {
  position: absolute;
  content: "";
  top: -3rem;
  width: 4rem;
  height: 1.5px;
  transform: rotate(90deg);
  background: var(--color-white-100);
}
.banner-links::after {
  position: absolute;
  content: "";
  bottom: -3rem;
  width: 4rem;
  height: 2px;
  transform: rotate(90deg);
  background: var(--color-white-100);
}