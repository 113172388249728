.title {
    border-bottom: #1976d2;
    padding:50px 70px;
    margin-top: 40px;
    font-size: 3rem;
}

.loading {
    width: 300px;
    height: 350px;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    align-self: start;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    padding: 10px;
}

.loading .divider {
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
    gap: 10px;
}

.loading .prod-name {
    margin-top: 40px;
}
.Single-searchBody{
    /* height: 100px; */
    /* padding-top: 2.5%; */
    width: 35%;
    margin-left: 4%;
    z-index: 3;
    /* position: absolute; */
    margin-bottom: 1%;
}