
.category-list{
    /* background-color: red; */
    display: flex;
    width:90% ;
    margin: auto;
    flex-direction: row;
    flex-wrap: wrap;
}
.category-card{
    width:30%;
    flex-direction: column;
  /* margin: auto; */
  height: 120px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.15);
  /* display: flex; */
  border-radius: 10px;
   margin-top: 1%;
   margin-bottom: 1%;
   margin-left: 1%;
   overflow-y: scroll;
}
.category-card-list{
    width: 100%;
    display: flex;
    padding: 1%;
    flex-direction: row;
    flex-wrap: wrap;
}
.catalog-card-list{
    width: 100%;
    display: flex;
    /* padding: 1%; */
}

.category-card-sub{
    border-radius: 4px;
    padding: 1%;
    border: 1px solid grey;
    margin-bottom: 1%;
    background: white;
    border-left:5px solid #F69A00;
    
}


.adminSectionprice {
    width: 25%;
    float: right; 
    /* background-color: red; */
}